
export default {
  namespace: 'global',

  state: {
    collapsed: true,
    notices: []
  },

  effects: {

  },

  reducers: {

  },

};
