// 全局函数文件
import moment from "moment";
import request from "@/utils/request";
import { v4 as uuidv4 } from 'uuid';

export function DeepCopy(obj) {
  if (typeof obj === "object" && obj) {
    return JSON.parse(JSON.stringify(obj));
  } else {
    return obj;
  }
}

export function msgF(msg, msgDetail) {
  let r = "";
  if (msg && !msgDetail) {
    r = msg;
  }
  if (!msg && msgDetail) {
    r = msgDetail;
  }
  if (msg && msgDetail) {
    r = msg + "," + msgDetail;
  }
  return r;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn("Two path are equal!"); // eslint-disable-line
  }
  const arr1 = str1.split("/");
  const arr2 = str2.split("/");
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ""));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(
      route => route !== item && getRelation(route, item) === 1
    );
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`
    };
  });
  return renderRoutes;
}

// 时间戳格式化
export function formatDate(timestamp, split = "") {
  const dateTime = new Date(Number(timestamp));
  const y = dateTime.getFullYear(); // 获取年
  let m = dateTime.getMonth() + 1; // 获取月
  m = m < 10 ? `0${m}` : m; // 判断月是否大于10
  let d = dateTime.getDate(); // 获取日
  d = d < 10 ? `0${d}` : d; // 判断日期是否大10
  return `${y}${split}${m}${split}${d}`; // 返回时间格式
}
// 将日期格式化成指定格式的字符串
export function formatTime(timestamp, ySplit="", hSplit='') {
  if(!timestamp) return
  const dateTime = new Date(Number(timestamp));
  const y = dateTime.getFullYear();
  let m = dateTime.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = dateTime.getDate();
  d = d < 10 ? `0${d}` : d;
  const h = dateTime.getHours();
  const min = dateTime.getMinutes();
  return `${y}${ySplit}${m}${ySplit}${d} ${h}${hSplit}${min}`;
}
// 返回如例：2020年03月21
export function formatDate2(str){
  if(!str) return str
  const dateObj = new Date(str)
  return dateObj.getFullYear() + '年' + (dateObj.getMonth() + 1)+ '月'+ dateObj.getDate()+'日'
}

// 时间转化成星期几
export function formatDateToWeek(date) {
  const weekArr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  const dateTime = new Date(date).getDay();
  return `${date} ${weekArr[dateTime]}`;
}
// 处理url
/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function downBlob(blob, name) {
  // 接收返回blob类型的数据
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob); // 创建下载的链接
  downloadElement.href = href;
  downloadElement.download = name; // 下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
}

export function thousandsFormat(num) {
  // 千分位分割,接收正整数
  var reg = /\d{1,3}(?=(\d{3})+$)/g;
  return (num + "").replace(reg, "$&,");
}

export function thousandsFormatBigger(num) {
  // 千分位分割,接收正整数
  if (Math.ceil(num / 10000).lentgth > 7) {
    return `${Math.ceil(num / 10000)}万`;
  } else {
    var reg = /\d{1,3}(?=(\d{3})+$)/g;
    let newNum = (num + "").replace(reg, "$&,");
    return String(newNum).indexOf(".") !== -1
      ? thousandsFormat(String(newNum).split(".")[0]) +
          "." +
          String(newNum).split(".")[1]
      : newNum;
  }
}

// 16进制转换成rgba
export function colorRgba(sHex, alpha = 1) {
  // 十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  /* 16进制颜色转为RGB格式 */
  let sColor = sHex ? sHex.toLowerCase() : sHex;
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    //  处理六位的颜色值
    var sColorChange = [];
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    // return sColorChange.join(',')
    // 或
    return "rgba(" + sColorChange.join(",") + "," + alpha + ")";
  } else {
    return sColor;
  }
}

// 学分申诉
export function dealQuarys(pm) {
  const p = DeepCopy(pm);
  if (p.collegeIdList && p.collegeIdList.length > 0) {
    p.collegeIdList = p.collegeIdList.map(v => {
      return Number(v.toString().replace("a-", ""));
    });
  } else {
    p.collegeIdList = undefined;
  }
  if (p.familyIdList && p.familyIdList.length > 0) {
    p.familyIdList = p.familyIdList.map(v => {
      return Number(v.toString().replace("b-", ""));
    });
  } else {
    p.familyIdList = undefined;
  }
  if (p.groupIdList && p.groupIdList.length > 0) {
    p.groupIdList = p.groupIdList.map(v => {
      return Number(v.toString().replace("c-", ""));
    });
  } else {
    p.groupIdList = undefined;
  }
  if (!p.creditBeginDate || !p.creditEndDate) {
    p.creditBeginDate = undefined;
    p.creditEndDate = undefined;
  }
  if (!p.appealBeginDate || !p.appealBeginDate) {
    p.appealBeginDate = undefined;
    p.appealEndDate = undefined;
  }
  if (p.creditType) {
    p.creditType = parseInt(p.creditType);
  } else {
    p.creditType = undefined;
  }
  if (p.statusList && p.statusList.length > 0) {
    p.statusList = p.statusList.map(v => Number(v));
  } else {
    p.statusList = undefined;
  }

  if (!p.appealOrderNum) {
    p.appealOrderNum = undefined;
  } else {
    p.appealOrderNum = p.appealOrderNum.trim();
  }

  if (!p.stuName) {
    p.stuName = undefined;
  } else {
    p.stuName = p.stuName.trim();
  }
  if (p.stuId) {
    p.stuId = parseInt(p.stuId.toString().trim());
  } else {
    p.stuId = undefined;
  }
  return p;
}

// 埋点
const { BI = {} } = window;
export function handleDataTrace(obj) {
  console.log(localStorage.getItem("userId"),localStorage.getItem("openId"),'弹出userId');
  if (localStorage.getItem("userId") || localStorage.getItem("openId")) {
    BI.traceV && BI.traceV(obj);
  }
}
export function handleDataTraceWithoutUserId(obj) {
  BI.traceV && BI.traceV(obj);
}

export function flatten(arr) {
  return arr.reduce(function(pre, cur) {
    if (!Array.isArray(cur)) {
      return [...pre, cur];
    } else {
      return [...pre, ...flatten(cur)];
    }
  }, []);
}

/*
 * 创收用
 * 1、数字转万元
 * 2、转千分位
 * 3、保留n位小数
 * 4、传入异常数据返回0
 * @num  number  待转换数字
 * @n    number  保留n位小数  default：0
 * return number
 * */
export function changeToThousandsForIncome(num, n) {
  if (isNaN(num)) {
    console.error("传递参数错误，请检查");
    return 0;
  }
  if (!n || isNaN(n)) {
    n = 0;
  }
  let number =
    Math.round((num / 10000) * Math.pow(10, Number(n))) /
    Math.pow(10, Number(n));

  return thousandsFormatDot(number.toFixed(Number(n)));
}

export function thousandsFormatDot(num) {
  var reg = /\d{1,3}(?=(\d{3})+$)/g;
  let newNum = (num + "").replace(reg, "$&,");
  return String(newNum).indexOf(".") !== -1
    ? thousandsFormat(String(newNum).split(".")[0]) +
        "." +
        String(newNum).split(".")[1]
    : newNum;
}

export function getTimeLeft(datetimeTo,hms) {
  // 计算目标与现在时间差（毫秒）
  let time1 = new Date(datetimeTo).getTime();
  let time2 = new Date().getTime();
  let mss = time1 - time2;
  if(time2>time1){
      return 'over'
  }
  // 将时间差（毫秒）格式为：天时分秒
  let days = parseInt(mss / (1000 * 60 * 60 * 24));
  let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = parseInt((mss % (1000 * 60)) / 1000);
  let hm = Math.floor((mss - (24 * 60 * 60 * 1000 * days) - (60 * 60 * 1000 * hours) - (60 * 1000 * minutes) - (seconds * 1000)) / 10);
  hours = hours > 9 ? hours : `0${hours}`;
  minutes = minutes > 9 ? minutes : `0${minutes}`;
  seconds = seconds > 9 ? seconds : `0${seconds}`;
  hm = hm > 9 ? hm : `0${hm}`;
  if(hms){
    return `${minutes}:${seconds}:${hm}`;
  }
  if (days < 1 && hours < 1 && minutes < 1 && seconds < 1){
      return 'over'
  } else if (days < 1){
      return hours + ":" + minutes + ":" + seconds
  } else {
      return days + ":" + hours + ":" + minutes + ":" + seconds
  }

}
// export co

//截取字符串，超出显示...
export function formatStringAsCount(string, number) {
  if (typeof string !== 'string') {
    return string
  }
  if (!number && number !== 0) {
    return string
  }
  if (string.length <= number) {
    return string
  }
  return string.slice(0, number) + '...';
}

// 从数据组随机n个不重复取值
export function randomByn(arr,n) {
  let mirror = [...arr]
  const result = []
  let count = mirror.length
  for (var i = 0; i < n; i++) {
      var index = ~~(Math.random() * count) + i;
      result[i] = mirror[index]
      mirror[index] = mirror[i]
      count --;
  }
  return result
}

/**
 *
 * 强制禁止用户修改微信客户端的字体大小 安卓
 *
 */
 export function forbidChangeFontSize(){
  if (typeof window.WeixinJSBridge == "object" && typeof window.WeixinJSBridge.invoke == "function") {
      handleFontSize();
  } else {
      if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", handleFontSize);
          document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
  }
}
function handleFontSize() {
  // 设置网页字体为默认大小
  window.WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
  // 重写设置网页字体大小的事件
  window.WeixinJSBridge.on('menu:setfont', function() {
    window.WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
  });
}
//  转化周几
export function getWeek(date) {
  if (!date) return;
  // date = isNaN(date) ? moment(date).format('x') : date
  let week = moment(date).day()
  switch (week) {
    case 1:
      return '周一'
    case 2:
      return '周二'
    case 3:
      return '周三'
    case 4:
      return '周四'
    case 5:
      return '周五'
    case 6:
      return '周六'
    case 0:
      return '周日'
  }
}
export function getQueryStringApp (name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  if (window.location.hash.indexOf("?") < 0) return null;
  let r = window.location.hash.split("?")[1].match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

// 手机号脱敏
export function phoneDesensitize(phone) {
  return phone.replace(/(\d{3})\d*(\d{4})/,'$1****$2')
}

// 新埋点
export function newAddDot(data) {
	// data.userId = localStorage.getItem('userId') || '-1'
  if(data.userId === -1 || data.userId === '-1') {
    const localUuid = localStorage.getItem('UUID')
    const uuid = localUuid || uuidv4().replace(/\-/g,'')
    localStorage.setItem("UUID", uuid)
    data.userId = uuid
  }
  return request('/traceUrl/actionInfo/add', { method: 'POST', data})
}

// CDN
export const CDN='https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/fe/study'
// 通过appKey匹配App信息
const APPINFO = {
  // 天天轻松学
  'ffd3cda7-e9c4-4bea-9540-c7d315cde987':{
    appName:'天天轻松学',
    appDes:'知识的光，点亮未来生活',
    scheme:'dailystudyscheme://com.sunland.dailystudy',
    logo: `${CDN}/sscp_bnx_logo.png`
  },
  // 费边教育
  // 'c4860edd-671f-4173-be05-a57fa00e587b':{
  //   appName:'费边教育',
  //   appDes:'理财是一种生活，学习是一种信仰',
  //   scheme:'fabiancollegescheme://com.sunland.fabiancollege',
  //   logo: `${CDN}/sscp_fb_logo.png`
  // },
  'c4860edd-671f-4173-be05-a57fa00e587b':{
    appName:'树帆教育',
    appDes:'学习是一种信仰',
    scheme:'fabiancollegescheme://com.sunland.fabiancollege',
    logo: `${CDN}/sscp_sfjy_logo.png`
  },
  // 三境书画
  'calligraphy-e9c4-4bea-9540-c7d315cde987':{
    appName:'三境书画',
    appDes:'习绘画，感受美',
    scheme:'sanjingscheme://com.sunland.calligraphy',
    logo: `${CDN}/sscp_sj_logo.png`
  },
  // 及象教育
  'fei-jixiang-e9c4-4bea-9540-c7d315cde987':{
    appName:'及象教育',
    appDes:'小课包罗万象，美好生活触手可及',
    scheme:'jxiangscheme://com.sunland.jxiangapp',
    logo: `${CDN}/sscp_jx_logo.png`
  },
  // 哈德良
  // 'hadeliang-be05-9540-0030-c7d315cde987':{
  //   appName:'哈德良',
  //   appDes:'培养兴趣爱好，提升专业知识',
  // },
  // 奥德赛
  'aodesai-e9c4-4bea-9540-c7d315cde987':{
    appName:'奥德赛',
    appDes:'有趣，有料，有温度',
    scheme:'odysseyscheme://com.sunland.odysseyapp',
    logo: `${CDN}/sscp_ods_logo.png`
  },
  // 方塘有课
  // 'fangtang-course-9540-c7d315cde987':{
  //   appName:'方塘有课',
  //   appDes:'精品好课，尽在方塘',
  // }
  // 花样小站  花样线上appId wx9c06e2d6befc70cd
  'huayang-station-9540-c7d315cde987':{
    appName:'',
    appDes:'来花样找兴趣一致的朋友',
    scheme:'sflowerscheme://com_sunland_nanshan',
    logo: `${CDN}/sscp_hyxz_logo.png`,
    appId:
      process.env.ENV_TYPE === "development"
        ? "wx9c06e2d6befc70cd"
        : "wx9c06e2d6befc70cd"
  }

}
export function getAppInfoByAppKey(_appKey){
  return APPINFO[_appKey]
}

// 判断是否是iphonex
export function isIPhoneX() {
  let u = window.navigator.userAgent;
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIOS) {
    return !!((window.screen.width == 375 && window.screen.height == 812) ||
        (window.screen.width == 414 && window.screen.height == 896) ||
        (window.screen.width == 390 && window.screen.height == 844));
  }else{
    return false
  }
}
// 防抖函数
export function debounce(fn, delay) {
  var ctx;
  var args;
  var timer = null;
  return function () {
    ctx = this;
    args = arguments;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      fn.apply(ctx, args);
      timer = null;
    }, delay);
  };
}
