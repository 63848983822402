/**
 * request 网络请求工具
 */
import { extend } from "umi-request";
import { routerRedux } from "dva/router";
import {
  SERVER_HOST,
  SERVER_HOST_QUESTIONNAIRE,
  SARTRE_SEVER_HOST,
  SERVER_HOST_OPEN,
  userUrl,
  traceUrl,
  FLOW_MARKET_RUL,
  XZURL,
  galleryUrl,
  HYXZ_URL,
  USER_AGREEMENT_URL,
  USER_AGREEMENT_GROUP_URL,
  baseUrl
} from "./constants";

import { notification } from "antd";
import storage from "./storage";

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。"
};


/**
 * 配置request请求时的默认参数
 */
const request = extend({
  prefix: null,
  // headers: {
  //   // 'X-Requested-With':'XMLHttpRequest',
  //   authorization: storage.getToken(),
  // },
  // credentials: "include"
  credentials: "same-origin"
});

const getQueryStringApp = name => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  if (window.location.hash.indexOf("?") < 0) return null;
  let r = window.location.hash.split("?")[1].match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
};
// 动态添加数据;
request.interceptors.request.use((url, options) => {
  console.log(url)

  if (url.indexOf("baokao") !== -1 || url.indexOf("/wxNum") !== -1 || url.indexOf("/commealApi") !== -1 ) {
    options.headers = Object.assign({}, options.headers, {
      "X-Requested-With": "XMLHttpRequest",
      authorization: storage.getToken(),
      token: "17f05893-404e-4aca-893b-abbc2e133b95"
    });
  } else {
    options.headers = Object.assign({}, options.headers, {
      "X-Requested-With": "XMLHttpRequest",
      authorization: storage.getToken()
    });
  }
  if(localStorage.getItem('wxAppId') || localStorage.getItem('appId') ||localStorage.getItem('mpAppId') || window.location.href.indexOf("&appKey") !== -1){
    options.headers = Object.assign({}, {
      wxAppId: localStorage.getItem('mpAppId')||localStorage.getItem('wxAppId')||localStorage.getItem('appId'),
      mpAppId: localStorage.getItem('mpAppId')||localStorage.getItem('wxAppId')||localStorage.getItem('appId')|| getQueryStringApp('appKey'),
      appId: localStorage.getItem('appId') || localStorage.getItem('mpAppId')|| localStorage.getItem('wxAppId') || getQueryStringApp('appKey'),
      brandId: localStorage.getItem('brandId') || getQueryStringApp('brandId')
    }, options.headers);
  }
  if (url.indexOf("/questionnaire/h5/config/getAppConfigInfo") > -1) {
    return {
      url: `https://m-bd.dayustudy.com/questionnaire${url.replace("/questionnaire", "")}`,
      options
    };
  }
  // 小站
  if (url.indexOf("smallapi") > -1) {
    if(url.indexOf("jx/smallapi") > -1) {
      return {
        url: `${XZURL}${url.replace("jx/", "")}`,
        options
      }
    } else {
      return {
        url: `${XZURL}${url}`,
        options
      }
    }
   ;
  }
  // 埋点请求头
  if(url.indexOf('/traceUrl')> -1) {
    return {
      url: `${traceUrl}${url.replace("/traceUrl", "")}`,
      options
    };
  }
  if (url.indexOf("/questionnaire") > -1) {
    return {
      url: `${SERVER_HOST_QUESTIONNAIRE}${url.replace("/questionnaire", "")}`,
      options
    };
  }
  if (url.indexOf('/sartreApp') > -1) {
    return {
      url: `${SARTRE_SEVER_HOST}${url}`,
      options
    }
  }

  if (url.indexOf('/open') > -1) {
    return {
      url: `${SERVER_HOST_OPEN}${url.replace("/open", "")}`,
      options
    }
  }

  if(url.indexOf('bfUser') > -1 || url.indexOf('invite_relation') > -1) {
    options.headers = Object.assign({}, options.headers, {
      withCredentials: true
    });
    let userUrlAll= `${userUrl}${url}`
    // 特殊处理
    if(url.indexOf('fl/bfUser')>-1){
      userUrlAll = `${userUrl.substring(0, userUrl.length - 4)}${url}`
    }
    return {
      url: userUrlAll,
      options
    }
  }
  // 流量市场
  if(url.indexOf('/flowMarketAd') > -1) {
    return {
      url: `${FLOW_MARKET_RUL}${url}`,
      options
    }
  }
  if(url.indexOf('/social') > -1){
    return {
      url: `${galleryUrl}${url}`,
      options
    }
  }
  // 花样小站收拾收拾
  if(url.indexOf('baseApi') > -1){
    return {
      url: `${HYXZ_URL}${url}`,
      options
    }
  }
  if(url.indexOf('order_api') > -1){
    return {
      url: `${HYXZ_URL}${url}`,
      options
    }
  }
  // 用户协议
  if(url.indexOf('/app/protocol') > -1){
    return {
      url: `${USER_AGREEMENT_URL}${url}`,
      options
    }
  }
  // 用户协议
  if(url.indexOf('my_protocol') > -1 || url.indexOf('/data/decrypt') > -1 || url.indexOf('/data/encrypt') > -1){
    return {
      url: `${USER_AGREEMENT_GROUP_URL}${url}`,
      options
    }
  }
  //小程序
  if(url.indexOf('aw/') > -1){
    return {
      url: `${baseUrl}${url}`,
      options
    }
  }
  return {
    url: `${SERVER_HOST}${url}`, // `http://21cfa5ea.ngrok.io${url}`, //
    options
  };
});

export default request;
