import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    routes: [
      {
        path: '/exception/403',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__403" */ '../exception/403'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../exception/403').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/exception/404',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__exception__404" */ '../exception/404'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../exception/404').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/authPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__authPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/authPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__authPage__index" */ '../authPage/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../authPage/index').default,
        name: '',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/oAuth/index',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "oAuth__index" */ '../../oAuth/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../../oAuth/index').default,
        name: '',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/freeStudy/questionnaire',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__freeStudy__questionnaire__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/freeStudy/questionnaire/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__freeStudy__questionnaire" */ '../freeStudy/questionnaire'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../freeStudy/questionnaire').default,
        name: '定制专属学习计划',
        routes: [
          {
            path: '/freeStudy/questionnaire/question',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__freeStudy__questionnaire__questionPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/freeStudy/questionnaire/questionPage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__freeStudy__questionnaire__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/freeStudy/questionnaire/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__freeStudy__questionnaire" */ '../freeStudy/questionnaire/questionPage'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../freeStudy/questionnaire/questionPage').default,
            name: '定制专属学习计划',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
        ],
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/diversionPage/diversion',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__diversionPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__diversionPage__diversion" */ '../diversionPage/diversion'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../diversionPage/diversion').default,
        name: '限时0元 ！5天直播课！ 名额有限',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/diversionPage/submitSuccess',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__diversionPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__diversionPage__submitSuccess" */ '../diversionPage/submitSuccess'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../diversionPage/submitSuccess').default,
        name: '限时0元 ！5天直播课！ 名额有限',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/diversionAuthPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__diversionAuthPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionAuthPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__diversionAuthPage" */ '../diversionAuthPage'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../diversionAuthPage').default,
        name: '限时特惠 ！5天直播课！ 名额有限',
        routes: [
          {
            path: '/diversionAuthPage/index',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__diversionAuthPage__diversionWrap__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionAuthPage/diversionWrap/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__diversionAuthPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionAuthPage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__diversionAuthPage" */ '../diversionAuthPage/diversionWrap/diversion'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../diversionAuthPage/diversionWrap/diversion').default,
            name: '限时特惠 ！5天直播课！ 名额有限',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
          {
            path: '/diversionAuthPage/submitSuccess',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__diversionAuthPage__diversionWrap__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionAuthPage/diversionWrap/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__diversionAuthPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionAuthPage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__diversionAuthPage" */ '../diversionAuthPage/diversionWrap/submitSuccess'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../diversionAuthPage/diversionWrap/submitSuccess')
                  .default,
            name: '报名成功',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
        ],
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/inviteGift',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__inviteGift__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/inviteGift/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__inviteGift" */ '../inviteGift'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../inviteGift').default,
        name: '邀请好礼',
        routes: [
          {
            path: '/inviteGift/index',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__inviteGift__invitePage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/inviteGift/invitePage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__inviteGift__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/inviteGift/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__inviteGift" */ '../inviteGift/invitePage'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../inviteGift/invitePage').default,
            name: '邀请好礼',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
        ],
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/inviteFriend',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__inviteFriend__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/inviteFriend/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__inviteFriend" */ '../inviteFriend'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../inviteFriend').default,
        name: '邀请好友送会员',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/receiveVip',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__receiveVip__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/receiveVip/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__receiveVip" */ '../receiveVip'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../receiveVip').default,
        name: '领取历代名画集会员',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/evaluateApp',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__evaluateApp" */ '../evaluateApp'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../evaluateApp').default,
        name: '评价App',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/questionAndAnswer',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__questionAndAnswer__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/questionAndAnswer/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__questionAndAnswer" */ '../questionAndAnswer'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../questionAndAnswer').default,
        name: '邀请回答',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/formappagreement',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__formappagreement__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/formappagreement/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__formappagreement" */ '../formappagreement'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../formappagreement').default,
        name: '协议',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addWechat48',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__addWechat48__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/addWechat48/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__addWechat48" */ '../addWechat48'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addWechat48').default,
        name: '添加老师微信',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/diversionListPage',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__diversionListPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionListPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__diversionListPage" */ '../diversionListPage'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../diversionListPage').default,
        name: '导流h5集合',
        routes: [
          {
            path: '/diversionListPage/index',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__diversionListPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/diversionListPage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__diversionListPage" */ '../diversionListPage/diversionWrap'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../diversionListPage/diversionWrap').default,
            name: '导流h5集合',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
        ],
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/downloadAPP',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__downloadAPP__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/downloadAPP/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__downloadAPP" */ '../downloadAPP'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../downloadAPP').default,
        name: '下载天天轻松学',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/app',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__appDownload" */ '../appDownload'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../appDownload').default,
        name: '',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/serviceAgreement',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__serviceAgreement" */ '../serviceAgreement'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../serviceAgreement').default,
        name: '尚德机构服务协议',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/secretsAgreement',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__secretsAgreement" */ '../secretsAgreement'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../secretsAgreement').default,
        name: '尚德机构隐私协议',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/appStudy/aboutUs',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__appStudy__aboutUs__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/appStudy/aboutUs/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__appStudy__aboutUs" */ '../appStudy/aboutUs'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../appStudy/aboutUs').default,
        name: '关于我们',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/appStudy/imgHtml',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__appStudy__aboutUs__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/appStudy/aboutUs/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__appStudy__aboutUs__imgHtml" */ '../appStudy/aboutUs/imgHtml'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../appStudy/aboutUs/imgHtml').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/oprationKB',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__oprationKB__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/oprationKB/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__oprationKB" */ '../oprationKB'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../oprationKB').default,
        name: 'H5看板',
        routes: [
          {
            path: '/oprationKB/index',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__oprationKB__oprationKBPage__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/oprationKB/oprationKBPage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__oprationKB__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/oprationKB/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__oprationKB" */ '../oprationKB/oprationKBPage'),
                  LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                    .default,
                })
              : require('../oprationKB/oprationKBPage').default,
            name: 'H5看板',
            exact: true,
            _title: 'sd_inspector_admin',
            _title_default: 'sd_inspector_admin',
          },
        ],
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/live',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__live" */ '../live'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../live').default,
        name: '直播',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/jxlive',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__jxLive__index" */ '../jxLive/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../jxLive/index').default,
        name: '直播',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addWeChatH5',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__addWeChatH5__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/addWeChatH5/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__addWeChatH5__index" */ '../addWeChatH5/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addWeChatH5/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/socialDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__socialDetail__model.js' */ '/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/pages/socialDetail/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__socialDetail__index" */ '../socialDetail/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../socialDetail/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/xzIndex',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__xzIndex__index" */ '../xzIndex/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../xzIndex/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/selectCity',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__selectCity__index" */ '../huayang/selectCity/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/selectCity/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/selectFriends',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__selectFriends__index" */ '../huayang/selectFriends/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/selectFriends/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/selectIns',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__selectIns__index" */ '../huayang/selectIns/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/selectIns/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/workDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__workDetail__index" */ '../huayang/workDetail/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/workDetail/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/oxAuth',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__oxAuth__index" */ '../huayang/oxAuth/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/oxAuth/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/xzLive',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__xzLive__index" */ '../xzLive/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../xzLive/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addExclusiveClass',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__addExclusiveClass__index" */ '../addExclusiveClass/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addExclusiveClass/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addQiwei37',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__addQiwei37__index" */ '../addQiwei37/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addQiwei37/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addQwForMhj',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__addQwForMhj__index" */ '../addQwForMhj/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addQwForMhj/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/showForWx',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__show__index" */ '../huayang/show/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/show/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addGzh',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__addGzhXz28__index" */ '../addGzhXz28/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addGzhXz28/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/userAgreement',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__userAgreement__index" */ '../userAgreement/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../userAgreement/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/gen',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__generateMpQrCode__index" */ '../generateMpQrCode/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../generateMpQrCode/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/jx',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__show__jx" */ '../huayang/show/jx'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/show/jx').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/hy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__huayang__show__zy" */ '../huayang/show/zy'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../huayang/show/zy').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/addTeacher',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__addTeacher__index" */ '../addTeacher/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../addTeacher/index').default,
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/paymentLink',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__paymentLink__index" */ '../paymentLink/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../paymentLink/index').default,
        name: '课程详情',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
      {
        path: '/result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__paymentLink__result__index" */ '../paymentLink/result/index'),
              LoadingComponent: require('/data/jenkins/jenkins/workspace/front_test-sscp_h5/src/components/PageLoading/index.js')
                .default,
            })
          : require('../paymentLink/result/index').default,
        name: '支付完成',
        exact: true,
        _title: 'sd_inspector_admin',
        _title_default: 'sd_inspector_admin',
      },
    ],
    _title: 'sd_inspector_admin',
    _title_default: 'sd_inspector_admin',
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
