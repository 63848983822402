import request from '@/utils/request';
//点赞/踩
export async function interactiveZan(params) {
  return request('/interactive/save',{method:'POST',data: params})
}
//生成h5二维码的接口
export async function createQrCode(params) {
  return request('/qrCode/createMpQrCode',{method:'POST',data: params})
}
//获取用户基本信息
export async function yearReportUser(params) {
  return request('/year/user',{method:'get',params})
}
