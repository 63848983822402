import {
  interactiveZan,
  createQrCode,
  yearReportUser,
} from '@/services/services';
export default {
  namespace: 'priseModal',
  state: {
    authParams:{},
    userParams:{},
    qrCodeImg:''
  },
  effects: {

    //  点赞/踩
    *interactiveZan({ payload ,callback}, { call, put }) {
      const result = yield call(interactiveZan,payload.params);
      if (result.code === 20000) {
        if (callback && typeof callback === 'function') {
          callback(result.data);
        }
      } else if (result) {
        // Toast.info(result.msg);
      }
    },
  //  生成二维码 createQrCode
    *getCreateQrCode({ payload ,callback}, { call, put }) {
      const result = yield call(createQrCode,payload.params);
      yield put({ type: 'save', payload: { qrCodeImg: result.data } });
      if (result.code === 20000) {
        if (callback && typeof callback === 'function') {
          callback(result.data);
        }
      }
    },
    // 获得用户基本信息
    *getYearReportUser({ payload ,callback}, { call, put }) {
      const result = yield call(yearReportUser,payload.params);
      if (result.code === 20000) {
        yield put({ type: 'save', payload: { yearUserData: result.data } });
        if (callback && typeof callback === 'function') {
          callback(result.data);
        }
      }else if(result.code === 20100 || result.code === 20200){
        yield put({ type: 'save', payload: { resultCode: result.code } });
        if (callback && typeof callback === 'function') {
          callback(result.code);
        }
      }
    },

  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    }
  },
}
