/**
 * 静态数据和全局配置
 * */

// localStorage Keys
const isFeiBian = window.location.href.indexOf('feibians.com') > -1
// 当前用户权限
export const ADMIN_AUTH = 'admin_auth';
// 当前用户信息
export const ADMIN_USER = 'admin_user';

// 微信公众号appid
export const WX_APP_ID = {
  production: 'wx7bd5f6ca9e06b09c',
  production2: isFeiBian? 'wx4f70395653f95f8a' : 'wx7bd5f6ca9e06b09c',
  pre: 'wx7bd5f6ca9e06b09c',
  localhost: 'wxa9cbffd8f7641ce1',
  localhost2: 'wxa9cbffd8f7641ce1',
  development: 'wxa9cbffd8f7641ce1',
  development2: 'wxa9cbffd8f7641ce1'
}[process.env.ENV_TYPE];

// 第三方组件 组件ID
export const COMPONENT_APPID = {
  production: 'wx593bbcf06a7ff833',
  production2: isFeiBian? 'wxc8d79e5fa12b8870' : 'wx593bbcf06a7ff833',
  pre: 'wx593bbcf06a7ff833',
  localhost: 'wx4af2895eca069973',
  localhost2: 'wx4af2895eca069973',
  development: 'wx4af2895eca069973',
  development2: 'wx4af2895eca069973',
}[process.env.ENV_TYPE];

//问卷调查 h5导流
export const SERVER_HOST_QUESTIONNAIRE = {
  production2: isFeiBian? 'https://bfmg-app.feibians.com/questionnaire' : 'https://m-bd.dayustudy.com/questionnaire',
  pre: 'https://m-bd.dayustudy.com/questionnaire',
  localhost: 'https://test-cloud-bf.dayustudy.com/free-study-questionnaire',
  localhost2: 'https://test-cloud-bf.dayustudy.com/free-study-questionnaire',
  development: 'https://test-cloud-bf.dayustudy.com/free-study-questionnaire',
  development2: 'https://test-cloud-bf.dayustudy.com/free-study-questionnaire',
}[process.env.ENV_TYPE];

// todo
export const SERVER_HOST_OPEN = {
  production2: isFeiBian ? 'https://h-fb.feibians.com/wx_api' : 'https://ym-h-bd.dayustudy.com/wx_api',
  localhost: 'https://test-cloud-bf.dayustudy.com/open-api',
  development: 'https://test-cloud-bf.dayustudy.com/open-api',
}[process.env.ENV_TYPE];


export const SERVER_HOST = {
  production2: 'https://h-bd.dayustudy.com/magic_cube',
  pre: 'https://h-bd.dayustudy.com/magic_cube_pre',
  development: 'https://h5-test.commeal.cn/magic_cube',
  development2: 'http://test.xd.admin2.ministudy.com',
}[process.env.ENV_TYPE];

export const userUrl = {
  development: 'https://test-cloud-bf.dayustudy.com/free-study-user-api/api/',
  localhost: 'https://test-cloud-bf.dayustudy.com/free-study-user-api/api/',
  production:'https://m-bd.dayustudy.com/usercenter/api/',
  production2: isFeiBian ? 'https://bfmg-app.feibians.com/usercenter/api/' : 'https://m-bd.dayustudy.com/usercenter/api/'
}[process.env.ENV_TYPE]

// 小程序社区接口
export const galleryUrl = {
  development: 'https://test-cloud-bf.dayustudy.com/community-work-api/',
  localhost: 'https://test-cloud-bf.dayustudy.com/community-work-api/',
  production2:isFeiBian ? 'https://bfmg-app.feibians.com/com_work_api/' : 'https://m-bd.dayustudy.com/com_work_api/'
}[process.env.ENV_TYPE]

// 小站api
export const XZURL = {
  development: 'https://small-site-test.huayangxiaozhan.com/',
  localhost: 'https://small-site-test.huayangxiaozhan.com/',
  production2: 'https://small-site.huayangxiaozhan.com/'
}[process.env.ENV_TYPE]

export const QW_AUTH_URL = {
  development: 'https://h5-test.commeal.cn',
  localhost: 'https://h5-test.commeal.cn',
  production2: 'https://ym-h-bd.dayustudy.com'
}[process.env.ENV_TYPE]
// 流量市场 跳转url
export const FLOW_MARKET_RUL = {
  development: 'https://test-cloud-bf.dayustudy.com/flow-api',
  localhost: 'https://test-cloud-bf.dayustudy.com/flow-api',
  production2: isFeiBian ? 'https://flow-fb.feibians.com/api' : 'https://flow.dayustudy.com/api'
}[process.env.ENV_TYPE]
// 花样小站
export const HYXZ_URL = {
  development: 'https://mall-gateway-test.dayustudy.com/',
  localhost: 'https://mall-gateway-test.dayustudy.com/',
  production2: 'https://mall-gateway.dayustudy.com/'
}[process.env.ENV_TYPE]
// 用户协议
export const USER_AGREEMENT_URL = {
  development: 'https://test-cloud-bf.dayustudy.com/free-study-joint',
  development2: 'https://test-cloud-bf.dayustudy.com/free-study-joint',
  localhost: 'https://test-cloud-bf.dayustudy.com/free-study-joint',
  production2: isFeiBian? 'https://bfmg-app.feibians.com' : 'https://m-bd.dayustudy.com'
}[process.env.ENV_TYPE]
// 用户协议（集团）
export const USER_AGREEMENT_GROUP_URL = {
  development: 'https://test-cloud-bf.dayustudy.com/free-study-user-api',
  development2: 'https://test-cloud-bf.dayustudy.com/free-study-user-api',
  localhost: 'https://test-cloud-bf.dayustudy.com/free-study-user-api',
  production2: isFeiBian? 'https://bfmg-app.feibians.com/usercenter' : 'https://m-bd.dayustudy.com/usercenter'
}[process.env.ENV_TYPE]
// =========================================== host =========

// 下载轻松学APP的地址
export const ANDROID_DOWNLOAD_APP_URL = {
  production: 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/daily_study_app/DailyApp_release.apk',
  production2: 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/daily_study_app/DailyApp_release.apk',
  pre: 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/daily_study_app/DailyApp_release.apk',
  localhost: 'http://172.16.108.155:3006/#/AndroidAppTest/android-FreeStudy-release',
  localhost2: 'http://172.16.108.155:3006/#/AndroidAppTest/android-FreeStudy-release',
  development: 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/daily_study_app/DailyApp_release.apk',
  development2: 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/daily_study_app/DailyApp_release.apk',
}[process.env.ENV_TYPE];

export const IOS_DOWNLOAD_APP_URL = {
  production: 'https://apps.apple.com/cn/app/天天轻松学/id1526262590',
  production2: 'https://apps.apple.com/cn/app/天天轻松学/id1526262590',
  pre: 'https://apps.apple.com/cn/app/天天轻松学/id1526262590',
  localhost: 'https://172.16.109.226/QA/appdailyversion.html',
  localhost2: 'https://172.16.109.226/QA/appdailyversion.html',
  development: 'https://apps.apple.com/cn/app/天天轻松学/id1526262590',
  development2: 'https://apps.apple.com/cn/app/天天轻松学/id1526262590',
}[process.env.ENV_TYPE];

// 轻松学APP后台服务地址
export const SARTRE_SEVER_HOST = {
  production2: isFeiBian ? 'https://h-fb.feibians.com/sarte-api' : 'https://st.dayustudy.com/api',
  pre: 'https://st.dayustudy.com/api',
  localhost: 'https://test-cloud-bf.dayustudy.com/sarte-api',
  localhost2: 'https://test-cloud-bf.dayustudy.com/sarte-api',
  development: 'https://test-cloud-bf.dayustudy.com/sarte-api',
  development2: 'https://test-cloud-bf.dayustudy.com/sarte-api',
}[process.env.ENV_TYPE];

// 埋点url
export const traceUrl = {
  localhost: 'https://test-cloud-bf.dayustudy.com',
  development: 'https://test-cloud-bf.dayustudy.com',
  production:'https://data-trace.dayustudy.com',
  production2:'https://data-trace.dayustudy.com'
}[process.env.ENV_TYPE]

//调用小程序接口
// 小程序社区接口
export const baseUrl = {
  development: 'https://test-cloud-bf.dayustudy.com/free-study-api/api/',
  localhost: 'https://test-cloud-bf.dayustudy.com/free-study-api/api/',
  production2:isFeiBian ? 'https://bfmg-app.feibians.com/pro2/api/' : 'https://m-bd.dayustudy.com/pro2/api/'
}[process.env.ENV_TYPE]
// appKey对应关系
// export const QSX_APPKEY = 'ffd3cda7-e9c4-4bea-9540-c7d315cde987'
// export const FEIBIAN_APPKEY = 'c4860edd-671f-4173-be05-a57fa00e587b'
// export const SANJING_APPKEY = 'calligraphy-e9c4-4bea-9540-c7d315cde987'
// export const JX_APPKEY = 'fei-jixiang-e9c4-4bea-9540-c7d315cde987'
// export const HADELIANG_APPKEY = 'hadeliang-be05-9540-0030-c7d315cde987'
export const MHJ_APPKEY = 'free-study-painting-9540-c7d315cde987'
